:root {
  --header-height: 120px;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;  /* ✅ Lato is now the main font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; // ✅ Keeps scrollbar space even when not needed
}

code {
  font-family: 'Lato', source-code-pro, Menlo, Monaco, Consolas,
    monospace;
}
