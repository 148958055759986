.header-top {
  height: var(--header-height);
  width: 100%;
  background-color: #000080;
  color: white;
  padding: 1rem 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 24pt;

  .logo {
    img {
      height: 100px;
      width: auto;
      border: 3px solid white;
      border-radius: 10px;
      padding: 6px;
      background-color: transparent;
    }
  }

  .nav-links {
    display: flex;
    gap: 2rem;
    margin-right: 10%;

    a {
      color: white;
      text-decoration: none;
      font-weight: 500;
      transition: color 0.3s ease;

      &:hover {
        color: #ffcc00;
      }
    }
  }
}
