.main-content {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .content-wrapper {
    position: relative;
    text-align: center;
    color: #fff;

    h1 {
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }

    .tagline {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    .cta-buttons {
      .primary-button {
        display: inline-block;
        background-color: #f44336;
        color: #fff;
        padding: 0.75rem 1.5rem;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        border-radius: 5px;
        cursor: pointer;
      }

      .secondary-button {
        display: inline-block;
        color: #fff;
        border: 2px solid #fff;
        padding: 0.5rem 1.2rem;
        font-size: 1rem;
        border-radius: 5px;
        text-decoration: none;
      }
    }
  }
}

.social-links {
  background-color: #fdd835;
  padding: 2rem;
  text-align: center;
  color: #b71c1c;

  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .social-items {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    .social-item {
      font-size: 1.1rem;

      a {
        color: #b71c1c;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
