.contact-page {
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-container {
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin: 2rem;
  color: white;

  h1 {
    margin-bottom: 1.5rem;
    text-align: center;
    color: navy;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input,
    textarea {
      padding: 0.75rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 6px;
      font-family: inherit;
      resize: vertical;
    }

    button {
      padding: 0.75rem;
      background-color: #ffcc00;
      border: none;
      border-radius: 6px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.success-message {
  background-color: #1e6e3b;
  color: white;
  padding: 1rem;
  border-radius: 6px;
  text-align: center;
  font-weight: bold;
}
